import { defineStore } from 'pinia';
import {
  type PartnerFilters,
  type PartnerType,
  PARTNER_DEFAULT_SORT,
} from '@/features/partners';

export const usePartnerFilters = defineStore('partnerFilters', () => {
  const filters = reactive<PartnerFilters>({ sort: PARTNER_DEFAULT_SORT });
  const resetFilters = () => {
    for (const key of Object.keys(filters) as [keyof PartnerFilters])
      if (key === 'sort') filters[key] = PARTNER_DEFAULT_SORT;
      else filters[key] = undefined;
  };
  return { filters, resetFilters };
});

export const usePartnerType = defineStore('partnerType', () => {
  const partnerType = ref<PartnerType>('master');
  return { partnerType };
});
